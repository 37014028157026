<template>
  <div :id="
	type!='cart'?
	`${productItem.componentId}${defaultPackage.packageSpecId?defaultPackage.packageSpecId: ''}`
	:productItem.cartItemId
	" class="hgp-cart-package" @click.stop>
    <div :class="[type == 'cart'?'cart-qty-input cart-text':'']">
      <el-popover popper-class="cart-package-poper" placement="top" :width="defaultPackage.scattered?300:245"
                  trigger="manual" v-model="popoverVisible">
        <div class="cart-package__content"
             v-if="(type == 'list' && productItem.prodPackages && productItem.prodPackages.length) || (type == 'cart')">
          <p>
<!--            <em class="tit" v-if="productItem.stockQty>0 && productItem.stockQty >= productItem.minOrderTotalQty">起购量：</em>-->
            <em class="tit" v-if="productItem.stockQty>0">起购量：</em>
            <em class="tit" v-else>起订量：</em>
            <span class="opt-qty"
                  v-if="productItem.stockQty>0 ">{{ (defaultPackage.baseUnit ==  productItem.saleUnit ? parseFloat(productItem.minBuyTotalQty.toFixed(3)) : parseFloat((productItem.minBuyTotalQty/1000).toFixed(3))) + productItem.saleUnitName}}</span>
            <!--&& productItem.stockQty >= productItem.minOrderTotalQty-->
            <span class="opt-qty"
                  v-else>{{ (defaultPackage.baseUnit == productItem.saleUnit ? parseFloat(productItem.minOrderTotalQty.toFixed(3)) : parseFloat((productItem.minOrderTotalQty/1000).toFixed(3))) + productItem.saleUnitName}}</span>
            <span class="scattered" v-if="defaultPackage.scattered">拆包价格</span>
          </p>
          <div class="item">
            <hgp-input-number v-model="defaultPackage.bigQty" :precision="0" :min="0"
                             @change="InputQty(defaultPackage.bigQty, 'big')"
                             @keyup.enter.native="InputQty(defaultPackage.bigQty, 'big')"></hgp-input-number>
            <span
              class="label"><span>{{defaultPackage.baseUnit != productItem.saleUnit ? parseFloat((defaultPackage.bigPackageQty/1000).toFixed(3)) : defaultPackage.bigPackageQty || ''}}</span>{{productItem.saleUnitName || ''}}/{{defaultPackage.bigPackageUnitName || ''}}</span>
          </div>
          <div class="item" v-if="defaultPackage.smallPackageUnitName && productItem.salePackageType!=3">
            <hgp-input-number v-model="defaultPackage.smallQty" :precision="0" :min="0"
                             @change="InputQty(defaultPackage.smallQty, 'small')"
                             @keyup.enter.native="InputQty(defaultPackage.smallQty, 'small')"></hgp-input-number>
            <span
              class="label"><span>{{ defaultPackage.baseUnit != productItem.saleUnit ?parseFloat((defaultPackage.smallPackageQty/1000).toFixed(3)) : defaultPackage.smallPackageQty  || ''}}</span>{{productItem.saleUnitName || ''}}/{{defaultPackage.smallPackageUnitName || ''}}</span>
            <span class="scattered"
                  v-if="defaultPackage.scattered">{{defaultPackage.unpackingRateName ? defaultPackage.unpackingRateName : defaultPackage.unpackingRate}}</span>
          </div>
          <div class="item" v-if="productItem.salePackageType == 1 || productItem.salePackageType == 0">
            <hgp-input-number v-model="defaultPackage.noPackQty"
                             :precision="productItem.baseUnitFraction?3:0"
                             :step="(productItem.baseUnitFraction && (defaultPackage.smallPackageUnitName && productItem.salePackageType!=3))?0.1:1"
                             :min="0"
                             @change="InputQty(defaultPackage.noPackQty, 'nopack')"
                             @keyup.enter.native="InputQty(defaultPackage.noPackQty, 'nopack')"></hgp-input-number>
            <span class="label">{{defaultPackage.baseUnitName}}</span>
            <span class="scattered"
                  v-if="defaultPackage.scattered">{{defaultPackage.scatteredRateName ? defaultPackage.scatteredRateName : defaultPackage.scatteredRate}}</span>
          </div>
        </div>
        <div slot="reference" class="add-cart-package" v-if="type == 'list'">
          <div class="cart-qty-input" @click="focusTotal">
            <el-input v-model="defaultPackage.totalThousandQty" type="number" :min="0" @blur="totalInput"
                      @keyup.enter.native="totalInput"
                      @keyup.native="defaultPackage.totalThousandQty = oninput(defaultPackage.totalThousandQty)"
                      @mousewheel.native.prevent></el-input>
            <span class="text">{{ productItem.saleUnitName }}</span>
          </div>
          <!--              <el-button type="primary" :disabled="productItem.purchaseAuth == 0" :class="{'name-btn-disabled':productItem.purchaseAuth == 0}" @click="submit">添加待采清单</el-button>-->
          <div class="flex align-center">
            <el-button type="primary" @click="submit">添加购物车</el-button>
            <i @click="settingLmMyfavorite()" class="iconfont font16 hand" :class="productItem.myFavoriteFlag?'icon-yishoucang':'icon-shoucang'" style="margin:6px 0 0 8px"/>
          </div>
        </div>
        <div slot="reference" v-if="type == 'cart'">
          <div @click="focusTotal">
            <!--  :disabled="productItem.ProductListInfo.SpotStockQty<=0"  -->
            <el-input v-model="defaultPackage.totalThousandQty" type="number" :min="0"
                      @blur="totalInput" @keyup.enter.native="totalInput"
                      @keyup.native="defaultPackage.totalThousandQty = oninput(defaultPackage.totalThousandQty)"
                      @mousewheel.native.prevent>
            </el-input>
          </div>
        </div>
      </el-popover>
      <span class="text" v-if="type == 'cart'">{{productItem.saleUnitName}}</span>
    </div>
    <div v-if="type == 'detail'" class="detail-package">
      <div class="edit-package">
        <em class="tit">选择包规</em>
        <el-button :class="{'check': index == checkIndex}" v-for="(item,index) in comPackage" :key="index"
                   @click="selectPackage(item, index)">{{ item.packageSpecNameNew }}</el-button>
      </div>
      <div class="min-qty">
        <em class="tit" v-if="productItem.inventoryQty>0">起购量</em>
        <em class="tit" v-else>起订量</em>
        <span class="opt-qty"
              v-if="productItem.inventoryQty>0 ">{{ (defaultPackage.baseUnit ==  productItem.saleUnit ? parseFloat(productItem.minimumBuyQty.toFixed(3)) : parseFloat((productItem.minimumBuyQty/1000).toFixed(3))) + productItem.saleUnitName}}</span>
        <span class="opt-qty"
              v-else>{{ (defaultPackage.baseUnit == productItem.saleUnit ? parseFloat(productItem.minimumQty.toFixed(3) + productItem
          .saleUnitName) : parseFloat((productItem.minimumQty/1000).toFixed(3))) + productItem.saleUnitName}}</span>
      </div>
      <div class="input-qty-package">
        <section>
          <div class="tail-package-opt">
            <div class="item">
              <hgp-input-number v-model="defaultPackage.bigQty" controls-position="right" :precision="0"
                               :min="0"  @change="InputQty(defaultPackage.bigQty, 'big')"
                               @keyup.enter.native="InputQty(defaultPackage.bigQty, 'big')"></hgp-input-number>
              <span class="label">{{defaultPackage.bigPackageUnitName || ''}}</span>
            </div>
            <div class="item"
                 v-if="defaultPackage.smallPackageUnitName && productItem.salePackageType!=3">
              <hgp-input-number v-model="defaultPackage.smallQty" :precision="0" :min="0"
                               controls-position="right" @change="InputQty(defaultPackage.smallQty, 'small')"
                               @keyup.enter.native="InputQty(defaultPackage.smallQty, 'small')"></hgp-input-number>
              <span class="label">{{defaultPackage.smallPackageUnitName || ''}}</span>
            </div>
            <div class="item" v-if="productItem.salePackageType == 1 || productItem.salePackageType == 0">
              <hgp-input-number v-model="defaultPackage.noPackQty"
                               :precision="productItem.baseUnitFraction?3:0"
                               :step="(productItem.baseUnitFraction && (defaultPackage.smallPackageUnitName && productItem.salePackageType!=3))?0.1:1"
                               :min="0" controls-position="right"
                               @change="InputQty(defaultPackage.noPackQty, 'nopack')"
                               @keyup.enter.native="InputQty(defaultPackage.noPackQty, 'nopack')"></hgp-input-number>
              <span class="label">{{defaultPackage.baseUnitName}}</span>
            </div>
            <div class="item">
              <el-input placeholder="请输入数量" class="cart-qty-input__detail"
                        v-model="defaultPackage.totalThousandQty" type="number" :min="0" @blur="totalInput"
                        @keyup.enter.native="totalInput"
                        @keyup.native="defaultPackage.totalThousandQty = oninput(defaultPackage.totalThousandQty)"
                        @mousewheel.native.prevent></el-input>
              <span class="text">{{productItem.saleUnitName}}</span>
            </div>
          </div>
          <div class="total-price">已选{{selectTotalAmount}}元</div>
        </section>
        <section class="tail-package-opt__btn">
          <el-button type="primary" @click="submit"
                     v-if="productItem.inventoryQty != null && productItem.inventoryQty > 0">
            加入购物车</el-button>
          <section v-else>
            <el-button type="warning" v-if="productItem.openReserve==0" class="name-btn-disabled" @click="warningInfo">库存不足</el-button>
            <el-button type="warning" @click="submit" v-else>预定</el-button>
            <span v-if="productItem.openReserve==0">（该产品暂不支持预订）</span>
          </section>
          <span style="margin-left: 8px;">{{productItem.inventoryQty > 0 ? (`现货：${defaultPackage.baseUnit != productItem.saleUnit?(parseFloat((productItem.componentStock/1000).toFixed(3)) + productItem.saleUnitName): (productItem.componentStock + productItem.saleUnitName)}`) : `${productItem.deliveryTimeStr && productItem.openReserve? '预计交期：'+ productItem.deliveryTimeStr:''}`}}</span>
        </section>
      </div>
      <div class="package-remark" v-if="defaultPackage.scattered">拆包价格
        {{defaultPackage.unpackingRate ? `${defaultPackage.smallPackageUnitName}${defaultPackage.unpackingRate}` : ''}}
        {{defaultPackage.scatteredRate && (productItem.salePackageType == 1 || productItem.salePackageType == 0) ? `${defaultPackage.nonPackageUnitName}${defaultPackage.scatteredRate}` : ''}}
      </div>
    </div>
    <!-- 加购成功提示 -->
    <hgp-dialog title="添加成功" :visible.sync="addSuccessDialog" @handleClose="handleClose" width="400px" class="no-print add-dialog">
      <div slot="content" style="padding: 24px;">
        <div class="dialog-cont">
          <i class="iconfont icon-chenggong1"></i>
          <div class="dialog-tit">
            <p>成功加入购物车！</p>
            <div style="font-size: 12px">共有{{lmCount}}件商品</div>
          </div>
        </div>
        <div class="dia-foot">
          <el-button type="primary" @click="goPage()">去购物车</el-button>
          <el-button class="cancel" @click="addSuccessDialog = false">继续购物</el-button>
        </div>
      </div>
    </hgp-dialog>
  </div>
</template>
<script>
import {GetAllianceNotFactoryProductUrl} from "@/api_lm/union";

import _ from 'lodash';
import {
  // addCart,
  getProductAmount
} from "@/api/cart/index.js";
import {
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex';
import { addCart as addCart1 } from "@/api_lm/lmPortals/gbApi";
import { addCart as addCart2 } from "@/api_lm/lmPortals/myGoods";
import {settingLmMyfavorite} from "@/api_lm/lmPortals/myGoods";
export default {
  name: "wlm-cart-package",
  props: {
    type: { // list、detail、cart
      type: String,
      required: true,
      default: ''
    },
    // 区分外联盟 购物车和快速下单
    type2:{
      type: String,
      default: 'cart'
    },
    productItem: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    popoverVisible: {
      type: Boolean,
      default: false
    },
    shoppingCartItemId: {
      type: [Number, String],
      default: ''
    },
    sourceType: {  //用于区分尾货/正品加购成功数量显示判断
      type: String,
      default: ''
    }
  },
  data() {
    return {
      qty: '',
      num1: '',
      num2: '',
      defaultPackage: {},
      checkIndex: null,
      leftOverShopCartNum: 0, //加购成功尾货数量
      norMalShopCartNum: 0, //加购成功正品数量
      lmCount: 0, //联盟采购单添加成功数量
      addSuccessDialog: false,
      selectTotalAmount: 0
    }
  },
  computed: {
    addCart(){
      return this.type2 == 'quickOrder'  ? addCart2 : addCart1
    },
    // ...mapGetters({
    //   userInfo: 'home/userInfo',
    // }),
    comPackage() {
      if (this.type != 'detail') return;
      // let temp = [];
      // if (this.productItem.prodPackages) this.productItem.prodPackages.forEach((item) => {
      // 	temp.push(item);
      // });
      return this.productItem.prodPackages;
    },
    // computeTotalAmount() {
    //   let num = 0;
    //   // 计算价格，不需要计算拆包价格
    //   // if (this.defaultPackage.totalThousandQty && !this.defaultPackage.scattered) {
    //   //   num = this.productItem.finalAmount * (this.defaultPackage.baseUnit != this.productItem
    //   //     .saleUnit ? 1000 : 1) * this
    //   //     .defaultPackage.totalThousandQty;
    //   // } else {
    //   //   // 拆包价格 拆包折扣百分比 UnpackingRate 拆零折扣百分比 ScatteredRate
    //   //   // defaultPackage.bigQty defaultPackage.smallQty defaultPackage.noPackQty
    //   //   num = this.defaultPackage.bigQty * this.defaultPackage.bigPackageQty * this.productItem
    //   //       .finalAmount +
    //   //     (this.defaultPackage.smallQty && this.productItem.salePackageType != 3 ? (this.defaultPackage
    //   //       .smallQty * this.defaultPackage.smallPackageQty * (this.productItem.finalAmount * this
    //   //       .defaultPackage.unpackingDiscount)) : 0) +
    //   //     (this.defaultPackage.noPackQty && (this.productItem.salePackageType == 1 || this.productItem
    //   //       .salePackageType == 0) ? (this.defaultPackage.noPackQty * (this.productItem
    //   //       .finalAmount * this.defaultPackage.scatteredDiscount)) : 0)
    //   // }
    //   // return parseFloat(num.toFixed(2));
  },
  watch: {
    productItem: {
      handler(val) {
        if(this.type == 'list') return;
        if(this.type == 'detail') {
          let prodPackages = this.productItem.prodPackages && this.productItem.prodPackages.length ? this.productItem.prodPackages[0] : {}
          this.defaultPackage = {
            ...{
              totalThousandQty: ''
            },
            ...JSON.parse(JSON.stringify(prodPackages))
          };
          if (this.defaultPackage && this.defaultPackage.packageSpecId) {
            this.selectPackage(this.defaultPackage, this.defaultPackage.totalThousandQty || 0)
          }
        }else if(this.type == 'cart') {
          let productItem = JSON.parse(JSON.stringify(this.productItem))
          productItem.bigQty = productItem.cartBigPackageQty;
          productItem.smallQty = productItem.salePackageType == 3 ? 0 : productItem.cartSmallPackageQty; // 等于3则为0
          productItem.noPackQty = productItem.salePackageType == 2 || productItem.salePackageType == 3 ? 0 : productItem.cartNonPackQty || 0;
          productItem.TotalQty = productItem.qty;
          this.defaultPackage = {
            ...{
              totalThousandQty: ''
            },
            ...JSON.parse(JSON.stringify(productItem))
          };
          if (this.defaultPackage.baseUnit == productItem.saleUnit) this.defaultPackage.totalThousandQty = productItem.TotalQty;
          else this.defaultPackage.totalThousandQty = productItem.TotalQty / 1000;
        }
      },
      deep: true,
      immediate: true
    },

  },
  mounted() {
    // if (this.type == 'list' || this.type == 'cart') this.defaultPackage = JSON.parse(JSON.stringify(this
    // 	.productItem)).prodPackages[0];
    // this.calculateTotal(0);
  },
  created() {
    // console.log(this.productItem)
  },
  updated() {},
  methods: {
    ...mapActions('lmPortals',['getCartCount']),
    // ...mapActions('add', ['setCart']),
    // ...mapMutations('addCart',['setThat']),

    // 请求 我的商品档 - 收藏/取消收藏
    async settingLmMyfavorite(){
      let {componentId,myFavoriteFlag} = this.productItem
      let {data} = await settingLmMyfavorite({
        componentId,
        isFavorite:!myFavoriteFlag
      })
      if (data.status == 10001) return this.$message.error(data.message);
      console.log(data,'请求 我的商品档 - 收藏/取消收藏')
      if (data.success) {
        this.productItem.myFavoriteFlag = !this.productItem.myFavoriteFlag
        this.$message.success(this.productItem.myFavoriteFlag?'收藏成功':'取消收藏')
        this.$emit('editSuccess')
      }else this.$message.error('收藏失败')
    },

    warningInfo(){
      this.$message.warning('该商品库存不足，暂不支持预订')
    },
    goPage(){
      this.addSuccessDialog = false
      this.$router.push(`/lmPortals/cart`)
    },
    handleClose(){
      this.addSuccessDialog = false
    },
    // 初始化数据
    init() {
      // this.productItem.componentId == 250946 && console.log(this.productItem.componentId, this.productItem.prodPackages)
      let prodPackages = this.productItem.prodPackages && this.productItem.prodPackages.length ? this
        .productItem.prodPackages[0] : {}
      this.defaultPackage = {
        ...{
          totalThousandQty: ''
        },
        ...JSON.parse(JSON.stringify(prodPackages))
      };
      // this.productItem.componentId == 250946 && console.log(this.productItem.componentId, this.defaultPackage)
      if (this.defaultPackage && this.defaultPackage.packageSpecId) {
        this.selectPackage(this.defaultPackage, this.defaultPackage.totalThousandQty || 0)
      }
    },
    InputQty(number, type) {
      if (type == 'big') {
        let bigVal = number || 0;
        let big = parseFloat(bigVal);
        if (big == null || big <= 0) {
          big = 0;
        }
        this.defaultPackage.bigQty = big;
      } else if (type == 'small') {
        let smallVal = number || 0;
        let small = parseFloat(smallVal);
        if (small == null || small <= 0) {
          small = 0;
        }
        this.defaultPackage.smallQty = small;
      } else if (type == 'nopack') {
        let nopackVal = number || 0;
        let nopack = parseFloat(nopackVal);
        if (nopack == null || nopack <= 0) {
          nopack = 0;
        }
        this.defaultPackage.noPackQty = nopack;
      }

      let bigQty = this.defaultPackage.bigQty || 0;
      let smallQty = this.defaultPackage.smallQty || 0;
      let big_package_qty = this.defaultPackage.bigPackageQty || 0;
      let small_package_qty = this.defaultPackage.smallPackageQty || 0;
      let noPackQty = this.defaultPackage.noPackQty || 0;
      this.defaultPackage.TotalQty = bigQty * big_package_qty + smallQty * small_package_qty + noPackQty;
      if (this.defaultPackage.baseUnit != this.productItem.saleUnit) {
        let totalThousandQty = parseFloat((this.defaultPackage.TotalQty / 1000).toFixed(6));
        this.defaultPackage.totalThousandQty = totalThousandQty;
      } else {
        this.defaultPackage.totalThousandQty = parseFloat(this.defaultPackage.TotalQty.toFixed(6));
      }
      if (big_package_qty > 0) {
        bigQty = parseInt(parseFloat((this.defaultPackage.TotalQty / big_package_qty).toFixed(6)));
        this.defaultPackage.bigQty = bigQty;
      }
      if (small_package_qty > 0) {
        smallQty = parseInt(parseFloat((this.defaultPackage.TotalQty - bigQty * big_package_qty) / small_package_qty).toFixed(6))
        this.defaultPackage.smallQty = smallQty;
      }
      // this.defaultPackage.noPackQty = this.defaultPackage.TotalQty - (bigQty * big_package_qty) - (small_package_qty * smallQty);
      // 因为el-input-number页面更新是一个一步的过程，在页面更新完成后修改数据
      this.$nextTick(() => {
        this.defaultPackage.noPackQty = this.defaultPackage.TotalQty - (bigQty * big_package_qty) - (small_package_qty * smallQty);
      })
      // 使用防抖，减少请求
      if(this.type == 'cart') this.submit1();
      if((this.type == 'detail' && this.sourceType=='tailCargo' && this.defaultPackage.totalThousandQty>this.productItem.inventoryQty) ||  (!this.productItem.openReserve && this.defaultPackage.totalThousandQty>this.productItem.stockQty)){ //尾货判断库存

        this.totalInput()
      }
      if(this.type == 'detail') this.calcSelectAmount()   // 计算已选商品价格
    },
    calcSelectAmount(){
      let params = {
        componentId: this.productItem.componentId,
        componentGsbPackageSpecId: this.productItem.prodPackages[0].packageSpecId,
        areaCode: localStorage.getItem('deliveryArea')?(JSON.parse(localStorage.getItem('deliveryArea')).regionCode).split(',')[2]:'',
        qty: this.defaultPackage.TotalQty
      }
      getProductAmount({hideLoading: true,...params}).then(res=>{
        if(res.data.status == '10001') return this.selectTotalAmount = 0;
        this.selectTotalAmount = res.data
      })
    },
    totalInput(e) {
      let total = 0;
      if((this.type == 'detail' && this.sourceType=='tailCargo' && this.defaultPackage.totalThousandQty>this.productItem.stockQty) || (this.productItem.openReserve==0 && this.defaultPackage.totalThousandQty>this.productItem.stockQty)){  //尾货/未开预售判断库存
        this.$message.warning(this.productItem.openReserve==0 && this.defaultPackage.totalThousandQty>this.productItem.stockQty ? '加购数超出商品库存限制':'购买数量最大不可超过库存数');
        this.defaultPackage.totalThousandQty = this.productItem.stockQty
      }
      if (this.defaultPackage.totalThousandQty != null && this.defaultPackage.totalThousandQty != "") {
        if (this.defaultPackage.baseUnit != this.productItem.saleUnit) {
          total = (parseFloat(this.defaultPackage.totalThousandQty) || 0) * 1000;
        } else {
          if (this.$isBaseUnitDecimalFraction(this.defaultPackage.baseUnit)) total = parseFloat(this
            .defaultPackage.totalThousandQty) || 0;
          else total = Math.ceil(this.defaultPackage.totalThousandQty) || 0;
        }
      }
      this.calculateTotal(total);
    },
    //计算数量
    calculateTotal(totalQty) {
      let total = 0;
      if (totalQty != null && totalQty != "") {
        total = parseFloat(totalQty) || 0;
      }
      let bigQty = this.defaultPackage.bigQty || 0;
      let smallQty = this.defaultPackage.smallQty || 0;
      let big_package_qty = this.defaultPackage.bigPackageQty || 0;
      let small_package_qty = this.defaultPackage.smallPackageQty || 0;
      let noPackQty = this.defaultPackage.noPackQty || 0;
      if (this.defaultPackage.bigPackageQty > 0) {
        if (this.productItem.salePackageType == 3) {
          bigQty = Math.ceil(total / this.defaultPackage.bigPackageQty);
          total = 0;
        } else {
          bigQty = parseInt(total / this.defaultPackage.bigPackageQty);
          total = parseFloat((total % this.defaultPackage.bigPackageQty).toFixed(6));
        }
      }
      if (this.defaultPackage.smallPackageQty > 0 && this.productItem.salePackageType != 3) {
        // 如果没有支，则按照小包规为最后的数量
        if (this.productItem.salePackageType != 1 && this.productItem.salePackageType != 0) {
          if (this.defaultPackage.smallPackageUnitName == '支') smallQty = Math.ceil(total / this.defaultPackage.smallPackageQty);
          else smallQty = parseFloat(total / this.defaultPackage.smallPackageQty);
          total = 0;
        } else {
          smallQty = parseInt(total / this.defaultPackage.smallPackageQty);
          total = parseFloat((total % this.defaultPackage.smallPackageQty).toFixed(6));
        }
      }
      if (this.productItem.salePackageType == 1 || this.productItem.salePackageType == 0) {
        noPackQty = total;
        if (this.defaultPackage.baseUnitName == '支') this.defaultPackage.noPackQty = Math.ceil(noPackQty);
        else this.defaultPackage.noPackQty = parseFloat(noPackQty);
        total = 0;
      }
      //还有待分配，即至少是按照小包规销售
      if (total > 0) {
        if (this.defaultPackage.smallPackageQty > 0 && this.productItem.salePackageType != 3) {
          let num = totalQty % this.defaultPackage.bigPackageQty;
          bigQty = parseInt(totalQty / this.defaultPackage.bigPackageQty);
          smallQty = parseInt(num / this.defaultPackage.smallPackageQty);
        } else {
          bigQty = parseInt(totalQty / this.defaultPackage.bigPackageQty);
        }
      }
      this.$nextTick(()=>{
        this.defaultPackage.bigQty = bigQty;
        this.defaultPackage.smallQty = smallQty;
      })

      this.defaultPackage.TotalQty = bigQty * big_package_qty + smallQty * small_package_qty + noPackQty;
      if (this.defaultPackage.baseUnit != this.productItem.saleUnit) {
        let totalThousandQty = parseFloat((this.defaultPackage.TotalQty / 1000).toFixed(6));
        this.defaultPackage.totalThousandQty = totalThousandQty;
      } else {
        this.defaultPackage.totalThousandQty = parseFloat(this.defaultPackage.TotalQty.toFixed(6));
      }
      if(this.type == 'cart' && this.defaultPackage.TotalQty != this.productItem.qty) this.submit();
      if(this.type == 'detail') this.calcSelectAmount()  // 计算已选商品价格
    },
    focusTotal() {
      console.log("focusTotal")
      // 如果是列表，则调用详情接口获取数据，将当前这条的数据合并
      if(this.type == 'cart') { //  && this.productItem.ProductListInfo.SpotStockQty>0
        this.$emit('resetVisible',this.productItem);
        // this.$emit('update:popoverVisible', true)
        return;
      }
      if (!this.popoverVisible) {
        console.log("getPackages触发---")
        this.$emit('resetVisible');
        this.$emit('getPackages', this.productItem)
      }
    },
    selectPackage(item, index) {
      this.defaultPackage = {
        ...{
          totalThousandQty: ''
        },
        ...item
      };
      this.initProductNum();
      this.defaultPackage.totalThousandQty = '';
      this.checkIndex = index;
    },
    initProductNum() {
      // if (this.productItem.stockQty != null && this.productItem.stockQty > 0) {
      // 	this.defaultPackage.TotalQty = this.productItem.salePackageMinBuyQty;
      // } else {
      // 	this.defaultPackage.TotalQty = this.productItem.salePackageMinOrderQty;
      // }
      this.calculateTotal(this.defaultPackage.totalThousandQty || 0);
    },
    // 小数点后最多3位；
    oninput(value) {
      // console.log(value, typeof value, value.replace(/[^\.\d]/g, ""));
      // if(this.defaultPackage.Base_Unit_Name == '支') return Math.ceil(value)
      let str = String(value);
      let len1 = str.substr(0, 1);
      let len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      if (len1 == "+") {
        str = "";
      }
      if (len1 == "-") {
        str = "";
      }

      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        let str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      if (str.indexOf(".") != -1 && str.indexOf(".") >= 100) {
        str = str.substr(0, 100) + str.substr(str.indexOf("."))
      } else if (str.indexOf(".") == -1) {
        if (str.length > 100) str = str.substr(0, 100)
      }
      //正则替换
      if (this.productItem.saleUnitFraction) { // 售卖单位允许输入小数
        str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
        str = str.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, "$1"); // 小数点后只能输 3 位
      } else {
        //不允许输入小数
        str = str.replace(/[^\d^\.]+/g, "").replace('.', '');
      }
      return str;
    },
    submit1: _.debounce(function() {
      this.submit();
    }, 500),
    submit() {
      // if (this.userInfo.userName == '' || this.userInfo.userName == undefined){
      //   // let cartInfo = {
      //   //   defaultPackage:this.defaultPackage,
      //   //   productItem:this.productItem,
      //   //   sourceType:this.sourceType,
      //   //   type:this.type,
      //   //   shoppingCartItemId:this.shoppingCartItemId,
      //   //   that:this
      //   // }
      //   this.setThat({that:this})
      //   return this.$emit('login')
      // }

      let minQuyQty = this.defaultPackage.baseUnit == this.productItem.saleUnit?this.productItem.minBuyTotalQty:parseFloat((this.productItem.minBuyTotalQty/1000).toFixed(3))
      let totalQty = this.defaultPackage.baseUnit == this.productItem.saleUnit ? this.defaultPackage.TotalQty : parseFloat((this.defaultPackage.TotalQty /1000).toFixed(3))
      let minimumQty = this.defaultPackage.baseUnit == this.productItem.saleUnit?this.productItem.minOrderTotalQty:parseFloat((this.productItem.minOrderTotalQty/1000).toFixed(3))

      if (this.productItem.stockQty != null && this.productItem.stockQty > 0) {
        if (totalQty < minQuyQty){
          this.$message.warning('购买量小于起购量，请您添加！');
          this.$emit('editSuccess');
          return
        }
        if((this.sourceType=='tailCargo' && this.defaultPackage.totalThousandQty>this.productItem.stockQty) || (this.productItem.openReserve==0 && this.defaultPackage.totalThousandQty>this.productItem.stockQty)){ //尾货判断库存
          this.$message.warning(this.productItem.openReserve==0 && this.defaultPackage.totalThousandQty>this.productItem.stockQty ? '加购数超出商品库存限制':'购买数量最大不可超过库存数');
          if(this.type == 'cart') this.$emit('editSuccess');
          return
        }
      } else {
        if (totalQty < minimumQty) return this.$message.warning(
          '预订量小于起订量，请您添加！');
      }
      if (!this.defaultPackage.TotalQty) {
        return this.$message.warning('购买量不能小于0！');
      }
      if(this.productItem.componentStock<0 || this.productItem.componentStock < this.defaultPackage.TotalQty) {
        this.$confirm("该商品库存不足需预订，可能影响到您的交期，确定是否加购？", "温馨提醒", {
          confirmButtonText: "添加购物车",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let params = {
              qty: parseFloat(this.defaultPackage.TotalQty.toFixed(6)),
              componentId: this.productItem.componentId,
              brandProductId: this.defaultPackage.brandProductId,
              mixPackageSpecId: this.defaultPackage.packageSpecId, //包规id
              bigPackageQty: this.defaultPackage.bigQty, //大包规购买数量
              smallPackageQty: this.defaultPackage.smallQty, //小包规购买数量
              nonPackQty: this.defaultPackage.noPackQty, //拆零数量
              prodType:2
            }
            if (this.type == 'cart') {
              params.shoppingCartItemId = this.shoppingCartItemId; //若为新增不用传
              params.hideLoading = true;
            }
            params = [params]
            this.addCart(params).then(res => {
              this.getCartCount()
              let data = res.data;
              if (data.status == 10001) return this.$message.warning(data.message);
              else if (data.success) {
                  // this.addSuccessDialog = true
                  this.$message.success('成功加入购物车');
                  this.leftOverShopCartNum = data.leftOverShopCartNum
                  this.lmCount = data.lmShopCartNum
                  this.norMalShopCartNum = data.norMalShopCartNum
                  // this.setCart(1);
                this.$emit('editSuccess');
              } else{
                this.$message.warning(data.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
        return
      }
      let params = {
        qty: parseFloat(this.defaultPackage.TotalQty.toFixed(6)),
        componentId: this.productItem.componentId,
        brandProductId: this.defaultPackage.brandProductId,
        mixPackageSpecId: this.defaultPackage.packageSpecId, //包规id
        bigPackageQty: this.defaultPackage.bigQty, //大包规购买数量
        smallPackageQty: this.defaultPackage.smallQty, //小包规购买数量
        nonPackQty: this.defaultPackage.noPackQty, //拆零数量
        prodType:2
      }
      if (this.type == 'cart') {
        params.shoppingCartItemId = this.shoppingCartItemId; //若为新增不用传
        params.hideLoading = true;
      }
      params = [params]
      this.addCart(params).then(res => {
        this.getCartCount()
        let data = res.data;
        if (data.status == 10001) return this.$message.warning(data.message);
        else if (data.success) {
          // this.$message.success('加入购物车成功！');
          this.$message.success('成功加入购物车');
          // this.addSuccessDialog = true
          this.lmCount = data.lmShopCartNum
          this.leftOverShopCartNum = data.leftOverShopCartNum
          this.norMalShopCartNum = data.norMalShopCartNum
          // this.setCart(1);
        } else this.$message.warning(data.msg)
        this.$emit('editSuccess');
      })
    }
  }
}
</script>
<style lang="scss">
.cart-package-poper {
  font-size: 12px;
  padding: 12px;

  .scattered {
    color: #323233;
    position: absolute;
    right: 0;
  }

  .cart-package__content {
    p {
      position: relative;
      line-height: 18px;
      color: #D40000;
    }
  }

  .el-input-number {
    margin-top: 8px;
    width: 132px;

    .el-input__inner {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
    }

    .el-input-number__increase,
    .el-input-number__decrease {
      background-color: #FFFFFF;
      height: 30px;
      line-height: 30px;
      color: #646566;
      width: 32px;
    }

    .el-input-number__increase:hover,
    .el-input-number__decrease:hover {
      background-color: #ff4000;
      color: #FFFFFF;
      border-radius: 0px;
    }

    .el-input-number__increase [class*=el-icon],
    .el-input-number__decrease [class*=el-icon] {
      transform: scale(1.2);
      font-weight: bold;
    }

    .el-input-number__increase.is-disabled,
    .el-input-number__decrease.is-disabled {
      color: #DCDEE0;
    }

    .el-input-number__increase.is-disabled:hover,
    .el-input-number__decrease.is-disabled:hover {
      background-color: unset;
      color: #DCDEE0;
    }
  }

  .item {
    position: relative;

    .label {
      color: #646566;
      margin-left: 8px;
    }

    .scattered {
      margin-top: 15px;
      color: #D40000;
    }
  }
}
.add-dialog{
  .dia-foot{
    margin-top: 24px;
    padding-left: 40px;
    .el-button{
      width: 120px;
      font-size: 14px;
      &.cancel{
        border: 1px solid $hgp-main-color;
        color: $hgp-main-color;
      }
    }
  }
  .dialog-cont{
    display: flex;
    align-items: center;
    .iconfont{
      font-size: 28px;
      color: #2DA641;
      margin-right: 12px;
    }
    .dialog-tit{
      font-size: 12px;
      color: #969799;
      line-height: 18px;
      p{
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }
  }
}
.hgp-cart-package {

  .cart-qty-input {

    .el-input {
      width: 68px;
    }

    .cart-text {
      .el-input {
        width: 88px;
      }
    }

    .el-input__inner {
      padding: 0 8px;
    }

    .text {
      margin-left: 4px;
    }

    &.cart-text .text {
      margin-left: 8px;
    }

    &.cart-text {
      display: inline-flex;
      align-items: center;
    }
  }

  .add-cart-package {
    .name-btn-disabled{
      background: #969799 !important;
      border-color: #969799 !important;
      color: #fff !important;
    }
    .el-button {
      width: 80px;
      margin-top: 6px;
      border-radius: 2px;
      padding: 7px 0;
      border-color: #155BD4;
      background-color: #155BD4;
      color: #fff;
    }

    .el-button--warning {
      background-color: #FDD701;
      border-color: #FDD701;
      color: #323233;
    }
  }

  .detail-package {
    padding-bottom: 16px;

    .tit {
      color: #969799;
      font-size: 14px;
    }

    .edit-package {
      display: flex;
      margin-bottom: 16px;

      .tit {
        margin-right: 16px;
        line-height: 30px;
      }

      .el-button {
        padding: 8px;
        color: #323233;
      }

      .check {
        border-color: $hgp-main-color;
      }
    }

    .min-qty {
      display: flex;
      margin-bottom: 14px;

      .tit {
        margin-right: 30px;
      }

      .opt-qty {
        color: #323233;
        font-size: 14px;
      }
    }

    .input-qty-package {
      display: flex;
      font-size: 14px;

      .el-icon-arrow-up:before {
        content: "\E6D9";
      }

      .el-icon-arrow-down:before {
        content: "\E6D8";
      }

      .el-input-number.is-controls-right .el-input-number__decrease {
        border-radius: 0 0 2px 0;
      }
    }

    .tail-package-opt {
      display: flex;

      // align-items: center;
      .label {
        margin-right: 4px;

      }

      .cart-qty-input__detail {
        width: 96px;
        margin-left: 4px;

        .el-input__inner {
          padding: 0 8px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }

      }

      .el-input-number {
        width: 72px;

        .el-input__inner {
          padding-left: 8px;
          padding-right: 20px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          text-align: left;
        }

        .el-input-number__increase:hover,
        .el-input-number__decrease:hover {
          background-color: $hgp-main-color;
          color: #FFFFFF;
        }

        &.is-controls-right[class*=mini] [class*=increase],
        &.is-controls-right[class*=mini] [class*=decrease] {
          line-height: 19px;
        }

        .el-input-number__increase,
        .el-input-number__decrease {
          border-color: #DCDEE0;
          width: 20px;
          background-color: #FFFFFF;
        }

        .el-input-number__increase.is-disabled,
        .el-input-number__decrease.is-disabled {
          color: #DCDEE0;
        }

        .el-input-number__increase.is-disabled:hover,
        .el-input-number__decrease.is-disabled:hover {
          color: #DCDEE0;
          background-color: unset;
        }

        .el-input-number__increase {
          border-radius: 0 2px 0 0;
        }
      }

      .el-input-number+.el-input-number {
        margin-left: 4px;
      }

    }

    .tail-package-opt__btn {
      .name-btn-disabled{
        background: #969799 !important;
        border-color: #969799 !important;
        color: #fff !important;
      }
      .el-button {
        width: 128px;
        height: 40px;
        border-radius: 2px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 24px;
      }

      .el-button--warning {
        background-color: #FDD701;
        border-color: #FDD701;
        color: #323233;
      }
    }
  }

  .total-price {
    background-color: #F2F3F5;
    margin-top: 4px;
    height: 24px;
    line-height: 24px;
    color: #646566;
    font-size: 14px;
    text-align: center;
  }

  .package-remark {
    color: #D40000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }

  .icon-shoucang,{
    &.active{
      color: #f45f22;
    }
    &:hover{
      color: #f45f22;
    }
  }
  .icon-yishoucang{
    color: #f45f22;
  }
}
</style>
