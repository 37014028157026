import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/directive';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/index.scss' // 系统样式
import "quill/dist/quill.core.css" // 富文本样式
import gather from './utils/utils';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import VueLazyload from 'vue-lazyload';
// 导入组件库
import work from "./packages/index";
import hgpUnit from './components';
import {toLmErp,routeERP, replaceUrl} from "@/utils/common";

Vue.use(work); // 注册 系统组件库
hgpUnit(Vue); // 注册 工宝组件库


Vue.use(VueLazyload, {
  loading: require('@/assets/images/lmPortals/home/lazyImg.png'), // 加载中的占位图
  error: require('@/assets/images/lmPortals/home/lazyImg.png'), // 加载失败的占位图
});

Vue.use(Viewer, { defaultOptions: { zIndex: 9999 } });
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
Vue.config.productionTip = false

//全局注册方法
Vue.prototype.routeERP = routeERP; // ERP页签跳转
Vue.prototype.$toLmErp = toLmErp ; // ERP内联盟页签跳转
Vue.prototype.replaceUrl = replaceUrl;
Vue.prototype.$eventHub= Vue.prototype.$eventHub ||  new Vue() // 全局组件通讯库

Vue.prototype.$isBaseUnitDecimalFraction = gather.isBaseUnitDecimalFraction;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
