import router from '@/router';
import store from '@/store';
/**
 * @param code 错误代码
 * @param message 错误信息
 */

export const errorCodeType = function(code,message){
  let errMessage = "未知错误"
  switch (code*1) {
    case 400:
      errMessage = '错误的请求'
      break
    case 401:
      try {
        errMessage = '登录已超时,请重新登录!'
      } catch (error) {
      } finally {
        const {path} = router.currentRoute;
        // 外联盟401登出
        if (path.includes('/lmPortals')){
          store.dispatch('lmPortals/resetStore');
          // router.push('/lmPortals/login');
          router.push('/lmPortals/home');
        }else{
          router.resolve('/');
        }
      }
      break
    case 403:
      errMessage = '无权限，请联系管理员!'
      break
    case 404:
      errMessage = '网络请求不存在!'
      break
    case 405:
      errMessage = '请求方法未允许'
      break
    case 408:
      errMessage = '请求超时'
      break
    case 500:
      errMessage = '服务器正在重启,请稍后重试!'
      break
    case 501:
      errMessage = '网络未实现'
      break
    case 502:
      errMessage = '服务器正在重启,请稍后重试!'
      break
    case 503:
      errMessage = '服务不可用'
      break
    case 504:
      errMessage = '网络超时'
      break
    case 505:
      errMessage = 'http版本不支持该请求'
      break
    default:
      errMessage = message?message:`其他连接错误 --${code}`
  }
  return errMessage
}
