<template>
  <div class="wlm-login">
    <el-dialog :visible.sync="dialogVisible" width="440px">
      <Login v-if="!isRegister" @changeType="isRegister = !isRegister" @onSuccess="onSuccess" />
      <Register v-else @changeType="isRegister = !isRegister" @onSuccess="onSuccess"/>
    </el-dialog>
  </div>
</template>
<script>
import Register from "@/components/wlm-login/register";
import Login from "@/components/wlm-login/login";

export default {
  name: "wlm-login",
  components: {
    Register,
    Login,
  },
  data() {
    return {
      isRegister: false, // 是否注册
      isResetPwd: false, // 是否重置密码
      dialogVisible: false
    };
  },
  methods: {
    onSuccess(){
      this.dialogVisible = false
      window.location.reload();
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.wlm-login {
  :deep(.el-dialog) {
    border-radius: 8px;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      color: rgba(0, 0, 0, 0.9);
      padding: 40px;
    }

    .el-dialog__footer {
      padding: 0;
    }
    .el-icon-close:before{
      font-size: 24px;
      color: #666666;
    }
  }

}
</style>
