import {getCartCount} from "@/api_lm/lmPortals/gbApi";
import {getUserInfo} from "@/api_lm/lmPortals";
import {createStorageUtils} from "@/store/storage";

// 工厂函数
const STORAGE_KEY = 'lmPortals';
const storageUtils = createStorageUtils(STORAGE_KEY);

const getDefaultState = () => {
  return {
    userInfo: storageUtils.get().userInfo || '', // 用户信息
    token: storageUtils.get().token || '', // 从存储中获取 token
    orderNum: {}, // 采购清单采购数量
    selectedSupplier:  storageUtils.get().selectedSupplier || [], // 选择的供应商
  }
}

const mutations = {
  // 重置state
  RESET_STORE: (state) => {
    storageUtils.clear();
    Object.assign(state, getDefaultState())
  },

  SET_TOKEN(state, token) {
    state.token = token;
    storageUtils.updateItem('token', token);
  },

  CLEAR_TOKEN(state) {
    state.token = '';
    storageUtils.removeItem('token');
  },

  SET_USERINFO(state, value) {
    state.userInfo = value;
    storageUtils.updateItem('userInfo', value);
  },

  CLEAR_USER_INFO(state) {
    state.userInfo = '';
    storageUtils.removeItem('userInfo');
  },

  SET_ORDER_NUM(state, value) {
    state.orderNum = value;
  },
  SET_SELECT_SUPPLIER(state, value){
    state.selectedSupplier = value
    storageUtils.updateItem('selectedSupplier', value);
    localStorage.setItem('selectedSupplier', JSON.stringify(value))
  }
};

const actions = {
  // 重置模块
  async resetStore({ commit }) {
    commit('RESET_STORE');
  },

  setToken({commit}, token) {
    commit('SET_TOKEN', token);
  },

  clearToken({commit}) {
    commit('CLEAR_TOKEN');
  },
  async getCartCount({commit}) {
    const {data} = await getCartCount();
    // console.log(data, '获取购物车数量');
    commit('SET_ORDER_NUM', data);
  },
  async getUserInfo({commit}) {
    const {data} = await getUserInfo();
    // console.log(data, '获取用户基本信息');
    commit('SET_USERINFO', data);
  },
  setSelectSupplier({commit}, value){
    commit('SET_SELECT_SUPPLIER',value)
  }
};

const getters = {
  token: state => state.token,
};

export default {
  namespaced: true,
  state:getDefaultState(),
  mutations,
  actions,
  getters
};
