import axios from 'axios';
import { errorCodeType } from '../utils/error-code-type';
import { Message, Loading } from 'element-ui';

// 创建axios实例
const service = axios.create({
    timeout: 45000, // 超时设置
    withCredentials: true, // send cookies when cross-domain requests
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        isLoading: true, // 可设置部分接口不需要Loading
        isError: true, // 可设置部分接口不需要错误提示
    }
});
//显示loading
let loading;
//正在请求的数量
let requestCount = 0;
// 401msg信息提示节流
let is401Throttled;

const showLoading = (config) => {
    // 如果配置了isLoading: false，则不显示loading
    if (requestCount === 0 && !loading && config.headers.isLoading) {
        //加载中显示样式可以自行修改
        loading = Loading.service({
            text: "拼命加载中，请稍后...",
            background: 'rgba(0, 0, 0, 0.7)',
            spinner: 'el-icon-loading',
        });
    }
    requestCount++;
};

//隐藏loading
const hideLoading = () => {
    requestCount--;
    if (requestCount == 0) {
        if (loading)   loading.close();
        loading = false
    }
};

// 请求拦截
service.interceptors.request.use(config => {
    showLoading(config);
    // 将请求头添加到config对象的某个属性中
    config.headersForResponse = config.headers;
    // 是否需要设置 token放在请求头
    // config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            let part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                // 对象处理
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        let subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config;
}, error => {
    Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
      hideLoading();
      const { data } = res;
      const code = data.code || 200;
      const msg = data.message || errorCodeType(code) || data.msg || errorCodeType('default');

      const isError = code !== 200;
      if (isError && res.headers.isError) {
          Message.error(msg);
          return Promise.reject(res);
      }

      const shouldShowError = [10001].includes(data.status);
      if (shouldShowError && res.headers.isError) {
          Message.error(msg);
          return Promise.reject(res);
      }

      return Promise.resolve(res);
  },
  error => {
      let message = '';
      hideLoading();
      if (error.config && error.config.headers && !error.config.headers.isError) return Promise.reject(error);
      if (error.response) {
          let { status, message: errorMessage } = error.response;
          message = errorCodeType(status, errorMessage);
          // 401重新登录
          if (status === 401) {
              if (!is401Throttled) {
                  is401Throttled = setTimeout(() => {
                      is401Throttled = null; // 重置计时器
                  }, 1000); // 设置节流的时间间隔，单位毫秒
                  Message.error(message);
              }
              return Promise.reject(error);
          }
      } else {
          // 请求超时或者网络有问题
          if (error.message.includes('timeout')) message = '请求超时！请检查网络是否正常';
          else message = error.message;
      }

      Message.error(message);
      return Promise.reject(error);
  },
);

export default service;
