import request from "@/api_lm/request"
const wlmUrl = process.env.VUE_APP_WLM_BASE_URL+"/wlm-api";
/**
 * 获取标准接口  /lmap/v1/baike/standard/stdtype-items
 * @params params
 */
export function getStandardList(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/standard/stdtype-items`,
        method: "get",
        params: query,
    });
}
/**
 * 获取大类接口   /lmap/v1/baike/category/big-category
 * @params params
 */
export function getBigcategory(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/category/big-category`,
        method: "get",
        params: query,
    });
}
/**
 * 获取小类接口     /lmap/v1/baike/category/small-category
 * @params params
 */
export function getSmallCategory(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/category/small-category`,
        method: "get",
        params: query,
    });
}
/**
 * 获取图样形状接口   /lmap/v1/baike/standard/shape-type-items
 * @params params
 */
export function getShapeType(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/standard/shape-type-items`,
        method: "get",
        params: query,
    });
}
/**
 * 获取标准接口    /lmap/v1/baike/standard/simple-standard-page
 * @params params
 */
export function getSqstandards(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/standard/simple-standard-page`,
        method: "get",
        params: query,
    });
}
/**
 * 获取标准接口2   /lmap/v1/baike/standard/simple-standard-replace-page
 * @params params
 */
export function getSqstandardsV2(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/standard/simple-standard-replace-page`,
        method: "get",
        params: query,
    });
}

/**
 * 获取3d图地址
 * @params params
 */
export function getweb3dUrl(query) {
    return request({
        url: wlmUrl + `/lmap/v1/tools/standard/web3ddisplayurl-standard`,
        method: "post",
        data: query,
    });
}


/**
 * 获取关键尺寸   /lmap/v1/baike/standard/small-category-keysize
 * @params params
 */
export function smallCategoryKeysize(query) {
    return request({
        url: wlmUrl + `/apigb/v1/customizationcenter/standard/small-category-keysize`,
        method: "get",
        params: query,
    });
}

/**
 * 获取大类下所有标准的直径数据   /lmap/v1/baike/fastener/nominals-by-bigcategory
 * @params params
 */
export function nominalsByBigcategory(query) {
    return request({
        url: wlmUrl + `/apigb/v1/baike/fastener/nominals-by-bigcategory`,
        method: "get",
        params: query,
        loading: false
    });
}

