<template>
  <div class="login-cont">
    <div>
      <!--登录 文案-->
      <template v-if="!isResetPwd">
        <div class="font28 text-bold">登录紧优网</div>
        <ul class="tabs">
          <li class="hand" @click="changeTabs(1)" :class="{active:tab==1}">密码登录</li>
          <li class="font15 m-lr-5 text-bold" style="color: #ccc">｜</li>
          <li class="hand" @click="changeTabs(2)" :class="{active:tab==2}">验证码登录</li>
        </ul>
      </template>
      <!--重置密码 文案-->
      <div v-else class="flex align-center justify-between">
        <div class="font28 text-bold">重置密码</div>
<!--        <div class="font14" style="">-->
<!--          <span style="color: #999">记起密码？</span>-->
<!--          <span class="hand" style="color: #0C65E7" @click="isResetPwd = false; password = ''">立即登录</span>-->
<!--        </div>-->
      </div>
      <!--手机号-->
      <el-input class="m-t-30 inp phone" v-model="phoneNumber" placeholder="请输入手机号"/>
      <!--密码-->
      <el-input type="password" v-if="tab == 1 && !isResetPwd" class="m-t-15 inp pwd" v-model="password"
                placeholder="请输入登录密码"/>
      <!--图形验证码-->
      <div class="m-t-15 flex align-center" v-if="isResetPwd || (tab === 2 || numLogin >=5)">
        <el-input v-model="captcha" class="inp graph" placeholder="请输入图形验证码"/>
        <div class="graphCode" @click="getCaptcha">
          <img :src="graphCode.captcha">
        </div>
      </div>
      <div v-if="tab == 1 && !isResetPwd" class="flex justify-end font14 m-t-10" >
        <div class="hand" style="color: #999"  @click="isResetPwd = true; password = ''">忘记密码?</div>
      </div>
      <!--短信验证码-->
      <div class="m-t-15 flex align-center" v-if="tab == 2 || isResetPwd">
        <el-input v-model="smsCode" class="inp sms" placeholder="请输入短信验证码"/>
        <div class="smsCode" :class="{disabled:countdown}" @click="handleGetSMSCode"
             v-text="countdown ? `重新发送(${countdown})` : '发送验证码'"/>
      </div>
      <!--重置密码-->
      <form>
        <el-input type="password" v-if="isResetPwd" class="m-t-15 inp pwd" v-model="password" placeholder="请输入登录密码"/>
      </form>
    </div>
    <div :class="{footerBtn}">
      <div class="login-btn m-t-32" @click="login">{{ isResetPwd ? '确认' : '登录' }}</div>
      <div class="plain-btn m-t-16" @click="changeType">{{ isResetPwd ? '记起密码？立即登录' : '免费注册账号' }}</div>
    </div>
  </div>
</template>
<script>
import Register from "@/components/wlm-login/register";
import {mapActions} from "vuex";
import {getCaptcha, pwdLoginApi, sendSmsApi, setPassword, smsLoginApi} from "@/api_lm/lmPortals";

export default {
  props:{
    footerBtn:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isRegister: false, // 是否注册
      numLogin: 0, // 登录次数
      tab: 1,
      isResetPwd: false, // 是否重置密码
      phoneNumber: '', // 手机号
      password: '', // 密码
      captcha: '', // 图形验证码
      smsCode: '', // 短信验证码
      // 图形码数据
      graphCode: {
        captcha: '',
        cid: ''
      },
      countdown: 0,
      timer: null,
    }
  },
  components: {
    Register
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    // 清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    ...mapActions('lmPortals', ['setToken', 'getUserInfo', 'resetState']),
    init() {
      this.getCaptcha()
    },
    // 切换选项卡
    changeTabs(val = 1) {
      this.captcha = '';
      this.smsCode = '';
      this.password = '';
      this.tab = val
    },
    // 请求 获取图形验证码接口
    async getCaptcha() {
      let {data} = await getCaptcha({
        width: 80,
        height: 40
      })
      console.log(data, '获取图形验证码接口')
      this.graphCode = data
    },

    // 请求 获取短信验证码接口
    async sendSms() {
      let {data} = await sendSmsApi({
        phoneNumber: this.phoneNumber,
        captcha: this.captcha,
        cid: this.graphCode.cid
      })
      if (data.captcha) this.graphCode = data.captcha
      // console.log(data, '获取短信验证码接口')
      if (data.success) {
        // 设置倒计时为60秒
        this.countdown = 60;
        // 启动倒计时
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$message.error(data.message)
        await this.getCaptcha
      }
    },

    // 请求 密码登录接口
    async pwdLoginApi() {
      this.numLogin++
      let {data} = await pwdLoginApi({
        username: this.phoneNumber,
        password: this.password,
        captcha: this.captcha,
        cid: this.graphCode.cid
      })
      // console.log(data,'密码登录接口')
      if (data.captcha) this.graphCode = data.captcha
      if (data.success) {
        this.setToken(data.token)
        await this.getUserInfo()
        await this.onSuccess()
        // await this.$router.push('/lmPortals/home')
      } else {
        if (data.errorType == 2) this.numLogin = this.numLogin + 5
        this.$message.error(data.message)
      }
    },

    // 请求 短信方式登录接口
    async smsLoginApi(fun) {
      let {data} = await smsLoginApi({
        phoneNumber: this.phoneNumber,
        cid: this.graphCode.cid,
        captcha: this.captcha,
        smsCode: this.smsCode
      })
      if (data.captcha) this.graphCode = data.captcha
      if (data.success) {
        this.setToken(data.token)
        await this.getUserInfo()
        if (fun) return fun()
        // await this.$router.push('/lmPortals/home')
        await this.onSuccess()
      } else {
        if (data.errorType == 2) this.numLogin = this.numLogin + 5
        this.$message.error(data.message)
      }
    },

    // 请求 修改密码
    async setPassword() {
      let {data} = await setPassword({
        password: this.password
      })
      // console.log(data,'修改密码')
      if (data.success) {
        this.$message.success('修改成功');
        // await this.$router.push('/lmPortals/home')
        await this.onSuccess()
      } else this.$message.error('修改失败');
    },


    // 监听 发送验证码
    handleGetSMSCode() {
      if (this.countdown > 0) return;
      if (!this.phoneNumber) return this.$message.warning('请正确输入手机号！');
      if (!this.captcha) return this.$message.warning('图形验证码不能为空！');
      this.sendSms()
    },

    // 监听 登录
    login() {
      if (!this.phoneNumber) return this.$message.warning('请正确输入手机号！');
      if (this.isResetPwd) {
        if (!this.captcha) return this.$message.warning('图形验证码不能为空！');
        if (!this.smsCode) return this.$message.warning('短信验证码不能为空！');
        if (!this.password) return this.$message.warning('新密码不能为空！');
        // 短信登录
        this.smsLoginApi(() => {
          this.setPassword() // 重置密码
        })
      } else {
        // 密码登录
        if (this.tab == 1) {
          if (this.numLogin >= 5 && !this.captcha) return this.$message.warning('图形验证码不能为空！');
          if (!this.password) return this.$message.warning('密码不能为空！');
          this.pwdLoginApi() // 密码登录
        }
        // 短信登录
        else {
          if (!this.captcha) return this.$message.warning('图形验证码不能为空！');
          if (!this.smsCode) return this.$message.warning('短信验证码不能为空！');
          this.smsLoginApi() // 短信登录
        }
      }

    },

    // 登录成功后续操作
    onSuccess(){
      this.$emit('onSuccess')
    },

    changeType(){
      if (this.isResetPwd) {
        this.isResetPwd = false; this.password = ''
      } else this.$emit('changeType')
    }
  }
}
</script>
<style lang="scss" scoped>
.login-cont{
  position: relative;
  height: 100%;
  .tabs {
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    li.active {
      font-weight: 500;
      color: #155bd4;
    }
  }

  :deep(.inp) {
    position: relative;

    .el-input__inner {
      height: 48px;
      padding-left: 96px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 44px;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      content: "|";
      position: absolute;
      left: 80px;
      top: 50%;
      transform: translateY(-50%);
      color: #eee;
    }

    &.phone:before {
      content: "手机号";
    }
    &.name:before {
      content: "姓名";
    }
    &.corporateName:before {
      content: "名称";
    }
    &.pwd:before {
      content: "密码";
    }

    &.graph:before {
      content: "图形码";
    }

    &.sms:before {
      content: "短信码";
    }
  }

  .graphCode {
    text-align: center;
    cursor: pointer;
    margin-left: 8px;
    width: 104px;
    height: 48px;
    padding: 4px 12px;
    background: #fdfdff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;

    img {
      width: 80px;
      height: 40px;
    }
  }

  .smsCode {
    cursor: pointer;
    margin-left: 8px;
    width: 104px;
    height: 48px;
    color: #fff;
    font-size: 12px;
    background: #0c65e7;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    &.disabled {
      color: #8cc5ff;
      background-color: #ecf5ff;
      border-color: #d9ecff;
    }
  }

  .footerBtn{
    position: absolute;
    bottom: 0;
  }
  .login-btn {
    cursor: pointer;
    color: #fff;
    height: 48px;
    background: #0c65e7;
    border-radius: 4px;
    border: 1px solid #0c65e7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
  }
  .plain-btn {
    cursor: pointer;
    color: #0C65E7;
    height: 48px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #0C65E7;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
  }
}


</style>
